
import '../../css/Projects/gallery.css';
import Card from './Card.js';

// import all images
import UniOrario from '../../assets/UniOrarioApp.png';
import BrewDay from '../../assets/BrewDay.png';
import Har from '../../assets/HAR.png';
import MoAlu from '../../assets/MO-ALU.png';
import CovidApp from '../../assets/CovidApp.png';


function Gallery() {
  const data = [{
    title: "Uni Orario",
    img: UniOrario,
    name: "Re-design Bicocca app & website.",
    description: "Everything starts by getting clarity around what we are trying to achieve. What should website’s main goal be? What does project.",
    top: "30px",
    color: "var(--blue)",
    width: "118.5px",
    imgTop: "25px",
  }, {
    title: "BrewDay!",
    img: BrewDay,
    name: "Spring web application.",
    description: "Everything starts by getting clarity around what we are trying to achieve. What should website’s main goal be? What does project.",
    top: "45px",
    color: "var(--yellow)",
    width: "280px",
    imgTop: "30px",
  }, {
    title: "MO-ALU",
    img: MoAlu,
    name: "VHDL digital circuit design.",
    description: "Everything starts by getting clarity around what we are trying to achieve. What should website’s main goal be? What does project.",
    top: "55px",
    color: "var(--orange)",
    width: "290px",
    imgTop: "31px",
  }, {
    title: "Covid App",
    img: CovidApp,
    name: "Covid stats app for iOS.",
    description: "Everything starts by getting clarity around what we are trying to achieve. What should website’s main goal be? What does project.",
    top: "30px",
    color: "var(--pink)",
    width: "118.5px",
    imgTop: "25px",
  }, {
    title: "HAR",
    img: Har,
    name: "Human Activity Recognition for iOS devices.",
    description: "Everything starts by getting clarity around what we are trying to achieve. What should website’s main goal be? What does project.",
    top: "55px",
    color: "var(--aqua)",
    width: "290px",
    imgTop: "31px",
  },
  ];

  return (
    <div class="gallery">
      <div class="containerTitle">
        <h3>Dive into my inspiring web project gallery, where development meets design.</h3>
      </div>
      <div class="row">
        <Card props={data[0]}/>
        <Card props={data[1]}/>
        <Card props={data[2]}/>
      </div>
      <div class="row">
        <Card props={data[3]}/>
        <Card props={data[4]}/>
        <Card props={data[0]}/>
      </div>
    </div>
  );
}

export default Gallery;
