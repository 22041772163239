
import '../css/General/header.css';
import { useEffect } from "react"


function Header({ value }) {
    useEffect(() => {

        const button = document.getElementById('highlight');
        const highlight = document.getElementsByClassName(value)[0];
        
        button.id = '';
        highlight.id = 'highlight';

    }, [])
  return (
    <div class="header">
        <h4>Andrea Esposito</h4>

        <div class="centerHeader">

            <form class="Page" action="/" method="GET">
                <input type="submit" id="highlight" class="Projects" value="Projects" />
                <hr />
            </form>

            <form class="Page" action="/about" method="GET">
                <input type="submit" id="" class="About" value="About" />
                <hr />
            </form>

        </div>

        <form class="Button" action="" method="GET">
            <input type="submit" value="Contact me" />
        </form>
    </div>
  );
}

export default Header;
