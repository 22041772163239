
import '../../css/Projects/hero.css';
import iPhone from '../../assets/UniOrarioApp.png';
import { useEffect } from "react"


function Hero() {
    useEffect(() => {
        window.addEventListener('scroll', function () {
            const position = window.scrollY;
            const title = document.getElementsByClassName('titlehero')[0];
            const img = document.getElementsByClassName('front')[0];

            var width = 648;
            var top = 138;

            var font = 270;
            var spacing = -15;
            var opacity = 100;

            if (position > 100 && position < 200) {
                width = 550;
                top = 120;
                font = 200;
                spacing = -12;
            } else if (position > 200 && position < 300) {
                width = 440;
                top = 80;
                font = 153;
                spacing = -8;
            } else if (position > 300 && position < 500) {
                width = 400;
                top = 65;
                font = 130;
                spacing = -6;
            } else if (position > 500) {
                width = 340;
                top = -800;
                font = 0;
                spacing = 0;
                opacity = 0;
            } else {
                width = 648;
                top = 138;
                font = 270;
                spacing = -15;
                opacity = 100;
            }

            img.style.width = width.toString() + 'px';
            img.style.top = top.toString() + 'px';
            img.style.transition = 'width 1s, top 1s';

            title.style.fontSize = font.toString() + 'px';
            title.style.opacity = opacity.toString() + '%';
            title.style.letterSpacing = spacing.toString() + 'px';
            title.style.transition = 'font-size 1s, letter-spacing 1s, opacity 3s';
        });
    }, [])
  return (
    <div class="bodyHero">
        <div class="hero">

            <h1 class="titlehero">Portfolio</h1>

            <img src={ iPhone } class="front" alt="iPhone 14 Pro" />

        </div>
     </div>
  );
}

export default Hero;
