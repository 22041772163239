
import Projects from './components/Projects/Projects.js';
import About from './components/About/About.js';

import { BrowserRouter as Router, Switch, 
  Route, Redirect,} from 'react-router-dom';


function App() {
    return (
    <>
      <Router>
        <Switch>
          {/* This route is for home component 
          with exact path "/", in component props 
          we passes the imported component*/}
          <Route exact path="/" component={ Projects } />
            
          {/* This route is for about component 
          with exact path "/about", in component 
          props we passes the imported component*/}
          <Route path="/about" component={ About } />
            
          {/* If any route mismatches the upper 
          route endpoints then, redirect triggers 
          and redirects app to home component with to="/" */}
          <Redirect to="/" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
