
import Hero from './Hero.js';
import TextIntroduction from './TextIntroduction.js';
import SelectedProject from './SelectedProject.js';
import Header from '../Header.js';
import Gallery from '../Projects/Gallery.js'


function Projects() {
    return (
      <>
        <Header value={"Projects"}/>
        <Hero />
        <TextIntroduction />
        <SelectedProject />
        <Gallery />
      </>
  );
}

export default Projects;
