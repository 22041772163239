
import '../../css/Projects/textintroduction.css';
import { useEffect } from "react"


function TextIntroduction() {
    useEffect(() => {
        window.addEventListener('scroll', function () {
            const position = window.scrollY;

            const phrase = document.getElementsByClassName('phrase')[0];
            const subtitle = document.getElementsByClassName('subtitle')[0];

            if (position > 500) {
                phrase.style.opacity = 100;
                subtitle.style.opacity = 100;
                phrase.style.transition = 'opacity 2s';
                subtitle.style.transition = 'opacity 2s';
            } else {
                phrase.style.opacity = 0;
                subtitle.style.opacity = 0;
            }
        });
    }, [])
  return (
      <div class="bodyText">

          <h4 class="phrase">Dive into my inspiring web project gallery, where development meets design.</h4>

          <div class="textContainer">
              <h3 class="subtitle">As a developer passionate in UI/UX Design, this website portfolio showcases a collection of all my projects. Crafting seamless user experiences and visually appealing interfaces, I aim to develop products with an amazing usability.</h3>
          </div>

      </div>
  );
}

export default TextIntroduction;
