
import '../../css/Projects/selectedproject.css';
import BigProject from '../../assets/UniOrarioWebApp.png';
import { useEffect } from "react"


function SelectedProject() {
    useEffect(() => {
        
        const frame = document.getElementsByClassName('frameRectangle')[0];
        const rectangle = document.getElementsByClassName('rectangleBig')[0];
        const imgBig = document.getElementsByClassName('projectbig')[0];

        const widthF = frame.offsetWidth;
        const widthI = imgBig.offsetWidth;
        var padding = (widthF - widthI) / 2;

        rectangle.style.paddingInline = padding.toString() + 'px';

        rectangle.addEventListener('mouseleave', function () {
            const widthF = frame.offsetWidth;
            const widthI = imgBig.offsetWidth;
            var padding = (widthF - widthI) / 2;

            rectangle.style.paddingInline = padding.toString() + 'px';
        });

        rectangle.addEventListener('mouseover', function () {
            const widthF = frame.offsetWidth;
            const widthI = imgBig.offsetWidth;
            var padding = (widthF - widthI) / 2;

            padding -= 20;
            rectangle.style.paddingInline = padding.toString() + 'px';
        });

    }, [])
  return (
    <div class="selectedProject">
        <div class="row1">
            <h4>(Selected work)</h4>
            <div class="discoverRow">
                <h4>Discover</h4>
                <svg width="13" height="14" viewBox="0 0 13 14">
                    <path d="M0.763494 7.35414L1.90128 6.20357L5.89631 10.1986V0.163086L7.55824 0.163086L7.55824 10.1986L11.5469 6.20357L12.6911 7.35414L6.72727 13.3179L0.763494 7.35414Z"></path>
                </svg>
            </div>
        </div>

        <div class="frameRectangle">
            <div class="rectangleBig">
                <img src={ BigProject } class="projectbig" alt="UniOrario project" />
            </div>
        </div>

        <div class="row2">
            <h4>Uni Orario</h4>
            <p>Re-design website and app for Milano-Bicocca university.</p>
        </div>
    </div>
  );
}

export default SelectedProject;
