
import Header from '../Header.js';


function About() {
    return (
      <>
        <Header value={"About"}/>
      </>
  );
}

export default About;
