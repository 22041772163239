
import '../../css/Projects/card.css';


function Card({props}) {
    const cardStyles = {
        container: {
            top: props.top,
        },
        title: {
            color: props.color,
        },
        image: {
            top: props.imgTop,
            width: props.width,
        },
    };

  return (
    <div class="card">
        <div class="image">
            <div class="rectangle">
                <div class="imageContainer" style={cardStyles.container} >
                    <h1 style={cardStyles.title} >{props.title}</h1>
                    <img src={props.img} alt="iPhone 14 Pro" style={cardStyles.image} />
                </div>
            </div>
        </div>

        <div class="textCard">
            <h4>{props.name}</h4>
            <p>{props.description}</p>
        </div>
    </div>
  );
}

export default Card;
